import React from 'react';
import { Link } from 'gatsby';
import { StoreContext } from '../context/store-context';
import { format } from '../utils/format';
import {calcShippingPrice} from '../utils/product';

export function SlideDrawer(props) {
    const { checkout, loading } = React.useContext(StoreContext);

    const emptyCart = loading === false && checkout.lineItems.length === 0;

    const shipPrice = !emptyCart && calcShippingPrice(checkout);

    if (emptyCart) {
        return '';
    }

    const drawerClasses = props.show ? 'side-drawer open' : 'side-drawer';

    return (
        <div className={drawerClasses}>
            <h2 className="sitteroo-h2 white has-text-centered">
                <button
                    type="button"
                    onClick={props.toggle}
                    className="delete is-large is-pulled-right p-5 m-1"
                >
                    <span>Delete</span>
                </button>
                <div className="is-clearfix" />
                <p className="sitteroo-monday-picnic mt-5">Summary</p>
            </h2>

            <div className="p-5">
                <table className="table">
                    <tbody>
                        {checkout.lineItems.map((item) => (
                            <tr key={item.id}>
                                <td className="is-size-4">
                                    {item.quantity} x {item.title}
                                </td>
                                <td className="is-size-4">
                                    {format(
                                        item.variant.priceV2.currencyCode,
                                        Number(item.variant.priceV2.amount) *
                                            item.quantity
                                    )}
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td className="is-size-4">
                                Shipping
                            </td>
                            <td className="is-size-4">
                                {shipPrice === 0 ? 'Free' : format(
                                    checkout.totalPriceV2.currencyCode,
                                    shipPrice
                                ) }
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>
                                <p className="is-sr-only">Total</p>
                            </th>
                            <th className="is-size-4">
                                {format(
                                    checkout.totalPriceV2.currencyCode,
                                    (checkout.totalPriceV2.amount*1 + shipPrice)
                                )}
                            </th>
                        </tr>
                    </tfoot>
                </table>
                <div className="has-text-centered">
                    <Link
                        className="sitteroo-button bg-salmon has-text-centered"
                        aria-label={`Shopping Cart with ${checkout.lineItems.length} items`}
                        to="/shop/cart"
                    >
                        My Shopping Cart
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default SlideDrawer;

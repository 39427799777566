/**
 * Formats a currency according to the user's locale
 * @param {string} currency The ISO currency code
 * @param {number} value The amount to format
 * @returns
 */
export const format = (currency, value) =>
    Intl.NumberFormat('en-AU', {
        currency,
        minimumFractionDigits: 2,
        style: 'currency',
    }).format(value);

export const getCurrencySymbol = (currency, locale = undefined) => {
    if (!currency) {
        return {};
    }

    const formatter = Intl.NumberFormat(locale, {
        currency,
        style: 'currency',
    });
    const parts = formatter.formatToParts(100);
    const { value: symbol } = parts.find((part) => part.type === 'currency');
    const formatted = formatter.format(100);
    const symbolAtEnd = formatted.endsWith(symbol);
    return { symbol, symbolAtEnd };
};

export const formatDate = (date) => {
    let _date = new Date(date);
    try {
        _date = new Intl.DateTimeFormat('en-AU').format(_date);
        _date.replaceAll('/', '.');
        return _date;
    } catch (e) {
        return '';
    }
};

export const formatTime = (time) => {
    let _date = new Date(`1970-01-01T${time}Z`);
    try {
        _date = _date.toLocaleTimeString('en-AU', {
            timeZone: 'UTC',
            hour12: true,
            hour: 'numeric',
            minute: 'numeric',
        });
        return _date;
    } catch (e) {
        return '';
    }
};

export const metaDescription = (text) => {
    try {
        const sentences = text.split('.') || [];
        let description = ''
        if (sentences.length > 1) {
            description = `${sentences[0]}.${sentences[1]}.`;
        }
        if (sentences.length === 1 || description.length > 150) {
            return `${sentences[0]}.`;
        }
        if (description.length < 150) {
            return text;
        }
        return description;
    } catch (e) {
        return '';
    }
}

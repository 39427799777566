export const ProductLink = ({title, handle}) => {
    let link = `/products/${handle}`;

    if (title === 'Birth Certificate') {
        link = '/star-map/#birth-certificate-creator';
    }

    if (title === 'Magic Star Map') {
        link = '/star-map/#star-map-creator';
    }

    return link;
}

export const calcShippingPrice = (info) => {
    if (!info) {
        return 0;
    }


    const weight = info?.lineItems.reduce((curr, item) => curr + item.variant.weight, 0);

    const total = info?.totalPriceV2?.amount * 1;

    if (total > 99) {
        return 0
    }
    if (weight === 0) {
        return 0;
    }
    if (total < 99) {
        const hasPoster = info?.lineItems?.find((item) =>
            item.title === 'Magic Star Map' || item.title === 'Birth Certificate'
        );
        if (hasPoster) {
            return 0;
        }
    }
    return 9.95;
}

export const sortByMinVariantPriceAscending = (a, b) => {
    const priceA = a.node.priceRangeV2.minVariantPrice.amount;
    const priceB = b.node.priceRangeV2.minVariantPrice.amount;

    return priceB - priceA;
};

import * as React from 'react';
import image from '../img/design/discount-modal.jpg';
import Contact from './Contact';

const DiscountModal = ({handleClose}) => (
    <div className="box section--salmon">
        <article className="media">
            <div className="media-content">
                <div className="columns">
                    <div className="column is-align-self-center">
                        <img
                            src={image}
                            alt="Discount"
                            className="image"
                        />
                    </div>
                    <div className="column">
                        <h2 className="sitteroo-blog-list-heading has-text-centered white">
                            Unlock 10% OFF Today!
                        </h2>
                        <p className="has-text-centered mb-2 is-size-7 brown">
                            Empower your child&apos;s education journey with our educational products.
                            Claim your 10% OFF on the first purchase and discover a world of learning opportunities.
                        </p>
                        <Contact
                            submitLabel="Shop Now and Save!"
                            wrapperClass="subscribe-modal"
                            cookieName="sitteroo_discount_subscribed"
                        />
                    </div>
                </div>
            </div>
        </article>
        <button
            type="button"
            className="modal-close is-large"
            aria-label="close"
            onClick={() => handleClose('sitteroo_discount_dismiss')}
        />
    </div>
);

export default DiscountModal;

import * as React from 'react';
import CartIcon from './CartIcon';
import { StoreContext } from '../context/store-context';

export function CartButton(props) {
    const { checkout } = React.useContext(StoreContext);

    const quantity = checkout.lineItems.length;

    if (quantity <= 0) {
        return '';
    }

    return (
        <button
            type="button"
            onClick={props.handleClick}
            className={['sitteroo-cart-button', props.className].join(' ')}
        >
            <CartIcon />
            {quantity > 0 && (
                <div className="sitteroo-cart-badge">{quantity}</div>
            )}
        </button>
    );
}

import * as React from 'react';
import image from '../img/design/ebook.png';
import Contact from './Contact';

const FreeEbookModal = ({handleClose}) => (
    <div className="box section--salmon">
        <article className="media">
            <div className="media-content">
                <div className="columns">
                    <div className="column is-align-self-center">
                        <img
                            src={image}
                            alt="Free e-book"
                            style={{ transform: 'scale(1.3)' }}
                            className="image"
                        />
                    </div>
                    <div className="column">
                        <h2 className="sitteroo-blog-list-heading has-text-centered white">
                            What you should know about learning through play?
                        </h2>
                        <p className="has-text-centered mb-2 is-size-7 brown">
                            Supercharge your child&apos;s growth: download our FREE eBook packed with 15 creative sensory and manipulative play ideas and easy homemade recipes!
                        </p>
                        <Contact
                            submitLabel="Get my free ebook"
                            wrapperClass="subscribe-modal"
                            cookieName="sitteroo_newsletter_subscribed"
                        />
                    </div>
                </div>
            </div>
        </article>
        <button
            type="button"
            className="modal-close is-large"
            aria-label="close"
            onClick={() => handleClose('sitteroo_newsletter_dismiss')}
        />
    </div>
);

export default FreeEbookModal;

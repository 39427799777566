import React from 'react';
import { Link } from 'gatsby';
import Logo from './Logo';
import { CartButton } from './CartButton';
import SideDrawer from './SideDrawer';
import window from '../utils/window';

const Navbar = class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false,
            drawerOpen: false,
            navBarActiveClass: '',
            isShop: window.location.href.match(/star-map|shop|products/gim) !== null,
            isBlog: window.location.href.match(/blog|tags/gim) !== null,
            isSubscribe: window.location.href.match(/contact\/subscribe/gim) !== null,
        };
    }

    drawerToggleClickHandler = () => {
        const drawerOpen = !this.state.drawerOpen;
        this.setState((previousState) => ({
            ...previousState,
            drawerOpen,
        }));

        if (!drawerOpen) {
            window.document.body.style.overflow = 'hidden';
            window.document.documentElement.classList.remove('is-clipped');
        } else {
            window.document.body.style.overflow = 'scroll';
            window.document.documentElement.classList.add('is-clipped');
        }
    };

    backdropClickHandler = () => {
        this.setState({
            drawerOpen: false,
        });
    };

    toggleHamburger() {
        // toggle the active boolean in the state
        const active = !this.state.active;
        const navBarActiveClass = active ? 'is-active' : '';
        this.setState((previousState) => ({
            ...previousState,
            active,
            navBarActiveClass,
        }));
    }

    render() {
        return (
            <>
                {!this.state.isSubscribe && <header className="sticky-header has-text-weight-semibold">
                    <h1 className="scrollable-text">Free shipping on all Posters or all orders over $99 in Australia.</h1>
                </header>}
                <nav
                    className="navbar has-shadow is-transparent is-fixed-top"
                    role="navigation"
                    aria-label="main-navigation"
                >
                    {this.state.drawerOpen ? (
                        <div
                            aria-hidden="true"
                            role="dialog"
                            className="backdrop"
                            onClick={this.backdropClickHandler}
                        />
                    ) : (
                        ''
                    )}

                    <SideDrawer
                        show={this.state.drawerOpen}
                        toggle={this.drawerToggleClickHandler}
                    />

                    <div className="container">
                        <div className="navbar-brand">
                            <Link
                                to="/"
                                className="navbar-item blog-logo"
                                title="Sitteroo"
                            >
                                <Logo
                                    isShop={this.state.isShop}
                                    isBlog={this.state.isBlog}
                                />
                            </Link>

                            <CartButton
                                className="nav-cart-mobile-version"
                                handleClick={this.drawerToggleClickHandler}
                            />

                            {/* Hamburger menu */}
                            <div
                                className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                                data-target="navMenu"
                                role="menuitem"
                                tabIndex={0}
                                onKeyPress={() => this.toggleHamburger()}
                                onClick={() => this.toggleHamburger()}
                            >
                                <span />
                                <span />
                                <span />
                            </div>
                        </div>
                        <div
                            id="navMenu"
                            className={`navbar-menu ${this.state.navBarActiveClass}`}
                        >
                            <div className="navbar-end has-text-centered">
                                <Link className="navbar-item" to="/">
                                Home
                                </Link>
                                <Link className="navbar-item" to="/blog/">
                                Blog
                                </Link>
                                <Link className="navbar-item" to="/star-map/">
                                Star Map
                                </Link>
                                <Link className="navbar-item" to="/shop/products/">
                                Shop
                                </Link>
                                <Link className="navbar-item" to="/about/">
                                About
                                </Link>

                                <CartButton
                                    className="nav-cart-desktop-version"
                                    handleClick={this.drawerToggleClickHandler}
                                />
                            </div>
                        </div>
                    </div>
                </nav>
            </>
        );
    }
};

export default Navbar;

import * as React from 'react';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';
import Footer from './Footer';
import Navbar from './Navbar';
import './all.sass';
import './alice-carousel.sass';
import './posters/styles.sass';
import useSiteMetadata from './SiteMetadata';
import Modal from './Modal';

const TemplateWrapper = ({ children }) => {
    const { title, description } = useSiteMetadata();
    return (
        <div>
            <Helmet>
                <html lang="en" className="has-navbar-fixed-top" />
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="og:description" content={description} />

                <link
                    rel="stylesheet"
                    href="https://fonts.googleapis.com/css?family=Comfortaa:400,700"
                />

                <link
                    rel="apple-touch-icon"
                    sizes="180x180"
                    href={`${withPrefix('/')}apple-touch-icon.png`}
                />
                <link
                    rel="icon"
                    type="image/png"
                    href={`${withPrefix('/')}favicon-32x32.png`}
                    sizes="32x32"
                />
                <link
                    rel="icon"
                    type="image/png"
                    href={`${withPrefix('/')}favicon-16x16.png`}
                    sizes="16x16"
                />

                <link
                    rel="mask-icon"
                    href={`${withPrefix('/')}safari-pinned-tab.svg`}
                    color="#ff4400"
                />
                <meta name="theme-color" content="#fff" />

                <meta property="og:type" content="business.business" />
                <meta property="og:title" content={title} />
                <meta property="og:url" content="/" />
                <meta
                    property="og:image"
                    content={`${withPrefix('/')}og-image.jpg`}
                />
                <meta
                    property="business:contact_data:street_address"
                    content="PO Box 668"
                />
                <meta
                    property="business:contact_data:locality"
                    content="SPIT JUNCTION"
                />
                <meta property="business:contact_data:region" content="NSW" />
                <meta
                    property="business:contact_data:postal_code"
                    content="2088"
                />
                <meta
                    property="business:contact_data:country_name"
                    content="Australia"
                />
            </Helmet>
            <Navbar/>
            <div className="sitteroo-content-container">
                {children}
            </div>
            <Footer />
            <Modal />
        </div>
    );
};

export default TemplateWrapper;

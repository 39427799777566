import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import DiscountModal from './DiscountModal';
import FreeEbookModal from './FreeEbookModal';

const getCookieValue = (name) =>
    document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || '';

const Modal = () => {
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const handleEscapeKey = (event) => {
        if (event.code === 'Escape') {
            setShowModal(false);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleEscapeKey);
        return () => document.removeEventListener('keydown', handleEscapeKey);
    }, []);

    useEffect(() => {
        if (navigator.userAgent.toLowerCase().includes('bot') || navigator.userAgent.toLowerCase().includes('crawler')) {
            return;
        }

        if (window.location.href.match(/contact/gim)) {
            return;
        }

        if (window.location.href.match(/shop|map|products/gim)) {
            if (getCookieValue('sitteroo_discount_dismiss') || getCookieValue('sitteroo_discount_subscribed')) {
                return null;
            } else {
                setModalContent(<DiscountModal handleClose={handleClose}/>);
            }
        } else {
            if ( getCookieValue('sitteroo_newsletter_dismiss') || getCookieValue('sitteroo_newsletter_subscribed')) {
                return null
            } else {
                setModalContent(<FreeEbookModal handleClose={handleClose}/>);
            }
        }

        const timeId = setTimeout(() => {
            document.documentElement.classList.add('is-clipped');
            setShowModal(true);
        }, 10000);

        return () => clearTimeout(timeId);
    }, []);

    const handleClose = useCallback((cookieName) => {
        setShowModal(false);
        const expires = new Date(Date.now() + 14 * 24 * 60 * 60 * 1000).toUTCString();
        document.cookie = `${cookieName}=true; expires=${expires};path=/;`;
        document.documentElement.classList.remove('is-clipped');
    }, []);

    if (!showModal) {
        return null;
    }

    const wrapperClass = ['modal', showModal ? 'is-active' : ''].join(' ');

    return ReactDOM.createPortal(
        <>
            <div className={wrapperClass}>
                <div
                    className="modal-background"
                    role="dialog"
                    onClick={handleClose}
                    onKeyDown={handleEscapeKey}
                />
                <div className="modal-content">
                    {modalContent}
                </div>
            </div>
        </>,
        document.body
    );
};

export default Modal;

import * as React from 'react';
import { navigate } from 'gatsby';
import window from '../utils/window';

function encode(data) {
    return Object.keys(data)
        .map(
            (key) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join('&');
}

const Contact = class extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isValidated: false, group: props.cookieName };
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const form = e.target;
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                ...this.state,
            }),
        })
            .then(() => {
                navigate(form.getAttribute('action'));
                window.document.cookie =
                    `${this.props.cookieName}=true; path=/;`;
            })
            // eslint-disable-next-line no-undef
            .catch((error) => alert(error));
    };

    render() {
        return (
            <form
                name="subscription"
                method="post"
                action="/contact/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
                className={[
                    'container',
                    'sitteroo-form',
                    this.props.wrapperClass || '',
                ].join(' ')}
            >
                <input type="hidden" name="form-name" value="subscription" />
                <div hidden>
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label>
                        Don’t fill this out:{' '}
                        <input name="bot-field" onChange={this.handleChange} />
                    </label>
                </div>

                <div className="field block">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label className="label is-sr-only" htmlFor="name">
                        Your name
                    </label>
                    <div className="control">
                        <input
                            type="text"
                            name="name"
                            onChange={this.handleChange}
                            id="name"
                            required
                            placeholder="name"
                            autoComplete="off"
                            className="input is-large has-text-centered"
                        />
                    </div>
                </div>
                <div className="field block">
                    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                    <label className="label is-sr-only" htmlFor="email">
                        Email
                    </label>
                    <div className="control">
                        <input
                            type="email"
                            name="email"
                            onChange={this.handleChange}
                            id="email"
                            required
                            placeholder="e-mail address"
                            autoComplete="off"
                            className="input is-large has-text-centered"
                        />
                    </div>
                </div>
                <div className="field block">
                    <div className="has-text-centered block">
                        <button
                            className="sitteroo-button bg-brown"
                            type="submit"
                        >
                            {this.props.submitLabel || 'sign up'}
                        </button>
                    </div>
                </div>
                <input
                    id="customer-group"
                    type="hidden"
                    name="group"
                    value={this.state.cookieName}
                />
            </form>
        );
    }
};

export default Contact;

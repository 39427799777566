import * as React from 'react';

function CartIcon(props) {
    return (
        <svg
            width={57}
            height={48}
            viewBox="0 0 57 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <title>Cart</title>
            <path
                d="M19.776 37.626C14.7147 37.626 9.61425 37.626 4.55299 37.626C2.86591 37.626 1.53193 36.959 0.629535 35.5073C0.0410162 34.5657 -0.0766876 33.5456 0.0410161 32.447C0.394127 29.7006 0.747239 26.9542 1.13958 24.2077C1.53193 21.3436 1.88504 18.4795 2.27739 15.5761C2.59126 13.1043 2.90514 10.6326 3.25825 8.16079C3.49366 6.51294 3.68983 4.86509 3.96447 3.21724C4.27835 1.56938 6.00467 0.117704 7.69176 0.0392347C8.24104 1.49083e-07 8.79033 0 9.33961 0C16.6372 0 23.8956 0 31.1933 0C32.6057 0 33.8612 0.392346 34.8421 1.49091C35.5483 2.27561 35.823 3.25647 35.9407 4.3158C36.2153 6.43447 36.4899 8.55314 36.7646 10.6718C37.0785 12.9474 37.3531 15.223 37.667 17.4986C37.9809 19.7742 38.2555 22.0498 38.5694 24.3254C38.844 26.4049 39.1187 28.4843 39.3933 30.5637C39.4718 31.1915 39.5502 31.8585 39.6287 32.4862C40.0995 35.2719 37.8632 37.626 35.1167 37.5867C29.977 37.5867 24.8765 37.626 19.776 37.626ZM19.776 34.7226C20.9138 34.7226 22.0124 34.7226 23.1502 34.7226C27.1129 34.7226 31.0756 34.7226 35.0383 34.7226C36.2938 34.7226 36.8823 34.0164 36.6861 32.7609C36.5684 32.0939 36.4899 31.3877 36.4115 30.7207C36.1368 28.6412 35.8622 26.5618 35.5875 24.4824C35.3129 22.3637 35.0383 20.2843 34.7636 18.1656C34.489 16.0862 34.2143 14.0067 33.9397 11.8881C33.6651 9.80864 33.3904 7.72921 33.1158 5.64978C33.0373 5.17896 32.9981 4.70815 32.9588 4.27657C32.8411 3.53111 32.3311 2.90336 31.2325 2.90336C23.621 2.90336 15.9703 2.90336 8.35875 2.90336C8.28028 2.90336 8.16257 2.90336 8.0841 2.90336C7.29941 2.94259 6.78936 3.41341 6.67166 4.15886C6.39702 6.19906 6.12238 8.23926 5.88697 10.2795C5.61233 12.3589 5.33768 14.4383 5.06304 16.5178C4.7884 18.6364 4.51376 20.7551 4.23912 22.8738C3.96447 24.9532 3.68983 27.0326 3.41519 29.1513C3.21902 30.5245 3.06208 31.8977 2.90514 33.2709C2.82667 33.8987 3.17978 34.448 3.80754 34.6834C4.08218 34.7618 4.39605 34.8011 4.6707 34.8011C9.69272 34.7226 14.754 34.7226 19.776 34.7226Z"
                strokeWidth={2}
                fill="#7B8368"
            />
            <path
                d="M19.8151 16.0077C15.6954 15.9685 12.1251 13.0259 11.3012 8.98475C11.1835 8.43546 11.1442 7.88618 11.1442 7.3369C11.105 6.5522 11.772 5.88522 12.5567 5.84598C13.3414 5.80675 14.0083 6.4345 14.0083 7.21919C14.0476 9.02398 14.7146 10.5149 16.0878 11.6919C17.9318 13.3006 20.5213 13.536 22.64 12.3197C24.484 11.2604 25.5041 9.6125 25.5826 7.49383C25.6218 6.59144 26.0926 5.96369 26.8773 5.88522C27.7405 5.76751 28.4859 6.39527 28.4859 7.25843C28.5251 8.51393 28.2505 9.69097 27.7405 10.8288C26.5242 13.4575 24.484 15.1053 21.6199 15.7723C21.0313 15.89 20.4036 15.9293 19.8151 16.0077Z"
                strokeWidth={2}
                fill="#7B8368"
            />
            <path
                d="M36.5572 43.5375C44.4762 43.5375 50.8957 37.2401 50.8957 29.4719C50.8957 21.7037 44.4762 15.4062 36.5572 15.4062C28.6383 15.4062 22.2188 21.7037 22.2188 29.4719C22.2188 37.2401 28.6383 43.5375 36.5572 43.5375Z"
                strokeWidth={2}
                fill="#F3B5A8"
            />
        </svg>
    );
}

export default CartIcon;
